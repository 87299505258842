import React from "react";
import { Link } from "gatsby";
import { useLocation } from "@reach/router";
import { Container, Row, Col, Button } from "react-bootstrap";

import styles from "./hero.module.scss";

const HeroHeader = ({ textOpacity }) => {
    const { search } = useLocation();

    return (
        <header className={styles.hero}>
            <Container fluid className="h-100 px-5">
                <Row className="h-100 align-items-center">
                    <Col md={5} className="mt-5 mt-md-0">
                        <h1
                            className={styles.title}
                            style={{
                                opacity: textOpacity,
                            }}
                        >
                            Hallo..
                        </h1>
                        <p
                            className={styles.desc}
                            style={{
                                opacity: textOpacity,
                            }}
                        >
                            Pelanggan Bikin Seragam <br />
                            Kami adalah Konveksi spesialis <br />
                            Seragam Kerja
                            <br />
                        </p>
                        <Button
                            variant="light"
                            size="lg"
                            className={styles.btnOrderNow}
                            as={Link}
                            to={`/hubungi-kami/${search}`}
                            style={{ opacity: textOpacity }}
                        >
                            Pesan Sekarang
                        </Button>
                    </Col>
                </Row>
            </Container>
        </header>
    );
};

export default HeroHeader;
